import { memo } from 'react'
import { Theme, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import Tuev from './tuev'
import Ekomi from './ekomi'
import GuenstigerGehtNicht from './guenstiger-geht-nicht'
import { getTrustCardDetails } from '../../utils/getTenantInfo.utils'
import SchufaNeutral from './schufa-neutral'

const useStyles = makeStyles<Theme>((theme) => ({
  footerContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    margin: '10px auto',
    maxWidth: '532px',
  },
  footerTitle: {
    marginBottom: theme.spacing(2),
  },
}))

interface TrustCardsProps {
  hasTuev?: boolean
  hasEkomi?: boolean
  hasGuenstigerGehtNicht?: boolean
  hasSchufaNeutral?: boolean
}

const TrustCards = ({
  hasTuev = true,
  hasEkomi = true,
  hasGuenstigerGehtNicht = true,
  hasSchufaNeutral = true,
}: TrustCardsProps) => {
  const { footerContainer, footerTitle } = useStyles()

  const { ekomi, tuev, guenstigerGehtNicht, schufaNeutral } =
    getTrustCardDetails()

  if (!hasEkomi && !hasGuenstigerGehtNicht && !hasSchufaNeutral && !hasTuev) {
    return <></>
  }

  return (
    <Grid className={footerContainer}>
      <Grid
        item
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='h5' align='center' className={footerTitle}>
          {hasSchufaNeutral && hasTuev && 'TÜV geprüft + SCHUFA-neutral'}
          {hasSchufaNeutral && !hasTuev && 'SCHUFA-neutral'}
        </Typography>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
          spacing={2}
        >
          {hasTuev && (
            <Grid
              item
              sx={{
                marginTop:
                  !hasEkomi && !hasGuenstigerGehtNicht ? '15px' : 'inherit',
              }}
            >
              <Tuev {...tuev} width='100px' />
            </Grid>
          )}
          {hasGuenstigerGehtNicht && (
            <Grid item>
              <GuenstigerGehtNicht {...guenstigerGehtNicht} />
            </Grid>
          )}
          {hasEkomi && (
            <Grid item>
              <Ekomi {...ekomi} />
            </Grid>
          )}
          {hasSchufaNeutral && (
            <Grid item>
              <SchufaNeutral {...schufaNeutral} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(TrustCards)
